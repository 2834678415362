/* eslint-disable jsx-a11y/label-has-associated-control */
import { Row, Select, Spin, Table } from 'antd';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
//import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { CalendarAddEntryModal } from 'components/CalendarAddEntryModal';

import { calendarEntriesService, homeStatisticsService, rssService } from 'config/services';

import { HttpError } from 'helpers/http';
import './styles.css';

import {
  HomeStatisticsOptions,
  HomeStats,
  MostReadOptions,
  MostRead,
  PendingArticlesCountStats,
  TodaysCountStats,
  AnalyticsCount,
  CalendarEntriesOptions,
} from 'types/services/homeStatistics';
import { RSSResponse } from 'types/services/rss';
import { SessionState, StoreState } from 'types/store';

import { GroupedColumn } from './GroupedColumn';
import { PieChart } from './Pie';
import { transformResource } from './utils';

export const Home = () => {
  const { Column } = Table;
  const { t } = useTranslation();
  const [showCalendarEntryModal, setShowCalendarEntryModal] = useState(false);

  const [dateFrom, setDateFrom] = useState(new Date().toISOString().substring(0, 10) + ' 00:00:00');
  const [dateTo, setDateTo] = useState(() => {
    const current = new Date();
    current.setHours(current.getHours() - current.getTimezoneOffset() / 60);
    return current.toISOString().substring(0, 19).replace('T', ' ');
  });
  const [readDateFrom, setReadDateFrom] = useState(() => {
    let current = new Date();
    current.setHours(current.getHours() - current.getTimezoneOffset() / 60);
    current.setHours(current.getHours() - 1);
    return current.toISOString().substring(0, 19).replace('T', ' ');
  });
  const [readDateTo, setReadDateTo] = useState(() => {
    const current = new Date();
    current.setHours(current.getHours() - current.getTimezoneOffset() / 60);
    return current.toISOString().substring(0, 19).replace('T', ' ');
  });
  const [buttonValue, setButtonValue] = useState('');

  const [filters, setFilters] = useState<HomeStatisticsOptions>({ dateFrom: dateFrom, dateTo: dateTo });
  const [readFilters, setReadFilters] = useState<MostReadOptions>({
    readDateFrom: readDateFrom,
    readDateTo: readDateTo,
    category: buttonValue,
  });
  const [countToday, setCountToday] = useState<number | undefined>(0);
  const [countYesterday, setCountYesterday] = useState<number | undefined>(0);
  const [countPendingArticles, setCountPendingArticles] = useState<Number | undefined>(0);
  const [countVisits, setcountVisits] = useState<Number | undefined>(0);
  const [articleDiff, setArticleDiff] = useState<number | undefined>(0);
  const [visitsDiff, setvisitsDiff] = useState<number | undefined>(110);

  const [local, setLocal] = useState(true);
  const [regional, setRegional] = useState(false);
  const [foreign, setForeign] = useState(false);

  const btnEnu = ['sve', 'vijesti', 'sport', 'showbiz', 'lifestyle', 'sci-tech'];
  const [btn, setBtn] = useState(btnEnu[0]);

  const [dateFromCalendar, setDateFromCalendar] = useState(new Date().toISOString().substring(0, 10) + ' 00:00:00');
  const [dateToCalendar, setDateToCalendar] = useState(new Date().toISOString().substring(0, 10) + ' 23:59:59');

  const { user } = useSelector<StoreState, SessionState>(({ session }) => session);

  const [isWeekSelected, setIsWeekSelected] = useState(false);

  const [calendarFilters, setCalendarFilters] = useState<CalendarEntriesOptions>({
    readDateFrom: dateFromCalendar,
    readDateTo: dateToCalendar,
    userEmail: user?.email,
  });

  const { data: RSSFeed } = useQuery<RSSResponse, HttpError>(['fetchRSS'], () => rssService.fetchRSS());
  const {
    data: calendarEntries,
    isLoading: entriesLoading,
    refetch: entriesRefetch,
  } = useQuery<any, HttpError>(['getCalendarEntries'], () => calendarEntriesService.list(calendarFilters));
  // const calendarData = [
  //   { time: '10:24', title: 'Prvi zadatak' },
  //   { time: '12:33', title: 'Drugi zadatak' },
  //   { time: '15:13', title: 'Treci zadatak' },
  //   { time: '16:45', title: 'Cetvrti zadatak' },
  // ];

  const { Option } = Select;

  const {
    data: categories,
    refetch,
    isLoading,
    isFetching,
    isError,
    error,
  } = useQuery<HomeStats, HttpError>(['getHomeStatistics'], () => homeStatisticsService.get(filters));

  const { data: textType } = useQuery<HomeStats, HttpError>(['getGroupedColumns'], () =>
    homeStatisticsService.getGroupedColumns(filters),
  );

  const { data: pendingArticles } = useQuery<PendingArticlesCountStats, HttpError>(['getPendingArticles'], () =>
    homeStatisticsService.getCountByPendingArticle(),
  );

  const { data: todaysArticles } = useQuery<TodaysCountStats, HttpError>(['getTodaysArticles'], () =>
    homeStatisticsService.getTodaysCount(),
  );

  const { data: mostReads, refetch: mostRead } = useQuery<MostRead, HttpError>(['getMostRead'], () =>
    homeStatisticsService.getMostRead(readFilters),
  );

  const { data: totalVisits } = useQuery<AnalyticsCount, HttpError>(['getTodayVisits'], () =>
    homeStatisticsService.getTotalVisits(),
  );

  useEffect(() => {
    setFilters({ dateFrom: dateFrom, dateTo: dateTo });
  }, [dateFrom, dateTo]);

  useEffect(() => {
    setReadFilters({ readDateFrom: readDateFrom, readDateTo: readDateTo, category: buttonValue });
  }, [readDateFrom, readDateTo, buttonValue]);

  useEffect(() => {
    setCalendarFilters({ readDateFrom: dateFromCalendar, readDateTo: dateToCalendar, userEmail: user?.email });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFromCalendar, dateToCalendar]);

  useEffect(() => {
    refetch();
  }, [filters, refetch]);

  useEffect(() => {
    mostRead();
  }, [readFilters, mostRead]);

  // useEffect(() => {
  //   entriesRefetch();
  //   console.log('rezultat poziva kad bude', calendarEntries);
  // }, [calendarFilters, showCalendarEntryModal, entriesRefetch]);

  useEffect(() => {
    if (todaysArticles !== undefined) {
      setCountToday(todaysArticles.data.countToday);
      setCountYesterday(todaysArticles.data.countYesterday);

      if (countToday && countYesterday) setArticleDiff(countToday - countYesterday);
    }
  }, [todaysArticles, countToday, countYesterday]);

  useEffect(() => {
    if (pendingArticles !== undefined) setCountPendingArticles(pendingArticles.data.count);
  }, [pendingArticles]);

  useEffect(() => {
    if (totalVisits && totalVisits?.data?.today.rows && totalVisits?.data?.yesterday.rows) {
      setcountVisits(totalVisits?.data?.today?.rows[0]?.metricValues[0]?.value);
      setvisitsDiff(
        totalVisits?.data?.yesterday?.rows[0]?.metricValues[0]?.value >
          totalVisits?.data?.today?.rows[0]?.metricValues[0]?.value
          ? ((totalVisits?.data?.yesterday?.rows[0]?.metricValues[0]?.value -
              totalVisits?.data?.today?.rows[0]?.metricValues[0]?.value) /
              totalVisits?.data?.yesterday?.rows[0]?.metricValues[0]?.value) *
              100
          : ((totalVisits?.data?.today?.rows[0]?.metricValues[0]?.value -
              totalVisits?.data?.yesterday?.rows[0]?.metricValues[0]?.value) /
              totalVisits?.data?.yesterday?.rows[0]?.metricValues[0]?.value) *
              100,
      );
    }
  }, [totalVisits]);

  if (!mostReads) {
    return (
      <div style={{ padding: '10px', textAlign: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }

  const onDateChange = (value: any) => {
    const current = new Date();
    current.setHours(current.getHours() - current.getTimezoneOffset() / 60);
    if (value === 'today') {
      setDateFrom(current.toISOString().substring(0, 10) + ' 00:00:00');
      setDateTo(current.toISOString().substring(0, 19).replace('T', ' '));
    } else if (value === 'yesterday') {
      current.setDate(current.getDate() - 1);
      setDateFrom(current.toISOString().substring(0, 10) + ' 00:00:00');
      setDateTo(current.toISOString().substring(0, 10) + ' 23:59:59');
    } else if (value === 'lastWeek') {
      const currentFrom = new Date();
      currentFrom.setDate(current.getDate() - 7);
      setDateFrom(currentFrom.toISOString().substring(0, 10) + ' 00:00:00');
      setDateTo(current.toISOString().substring(0, 19).replace('T', ' '));
    } else if (value === 'lastMonth') {
      const currentFrom = new Date();
      currentFrom.setDate(current.getDate() - 30);
      setDateFrom(currentFrom.toISOString().substring(0, 10) + ' 00:00:00');
      setDateTo(current.toISOString().substring(0, 19).replace('T', ' '));
    }
  };

  const onReadDateChange = (value: any) => {
    const current = new Date();
    current.setHours(current.getHours() - current.getTimezoneOffset() / 60);
    if (value === 'lastHour') {
      current.setHours(current.getHours() - 1);
      setReadDateFrom(current.toISOString().substring(0, 19).replace('T', ' '));
      current.setHours(current.getHours() + 1);
      setReadDateTo(current.toISOString().substring(0, 19).replace('T', ' '));
    } else if (value === 'today') {
      setReadDateFrom(current.toISOString().substring(0, 10) + ' 00:00:00');
      setReadDateTo(current.toISOString().substring(0, 19).replace('T', ' '));
    } else if (value === 'yesterday') {
      current.setDate(current.getDate() - 1);
      setReadDateFrom(current.toISOString().substring(0, 10) + ' 00:00:00');
      setReadDateTo(current.toISOString().substring(0, 10) + ' 23:59:59');
    } else if (value === 'lastWeek') {
      const currentFrom = new Date();
      currentFrom.setDate(current.getDate() - 7);
      setReadDateFrom(currentFrom.toISOString().substring(0, 10) + ' 00:00:00');
      setReadDateTo(current.toISOString().substring(0, 19).replace('T', ' '));
    }
  };

  const onCalendarDateChange = (value: any) => {
    // postaviti varijable za datume koji ce se mijenjati u kalendaru
    const current = new Date();
    current.setHours(current.getHours() - current.getTimezoneOffset() / 60);
    if (value === 'today') {
      setDateFromCalendar(current.toISOString().substring(0, 10) + ' 00:00:00');
      setDateToCalendar(current.toISOString().substring(0, 10) + ' 23:59:59');
      setIsWeekSelected(false);
    } else if (value === 'nextWeek') {
      const currentFrom = new Date();
      currentFrom.setDate(current.getDate() + 7);
      setDateFromCalendar(current.toISOString().substring(0, 10) + ' 00:00:00');
      setDateToCalendar(currentFrom.toISOString().substring(0, 10) + ' 23:59:59');
      setIsWeekSelected(true);
    }
  };

  const toggleCalendarAddEntryModal = () => {
    setShowCalendarEntryModal((value) => !value);
  };

  if (isError) {
    return (
      <div>
        <pre>{JSON.stringify(error, undefined, 2)}</pre>
      </div>
    );
  }

  function transform(mostReads: MostRead) {
    return mostReads.data.map((r) => transformResource(r));
  }

  function handleButtons(value: any) {
    if (value.target.value === 'sve') {
      setBtn(btnEnu[0]);
      setButtonValue('1,3,4,5,184');
    } else if (value.target.value === 'vijesti') {
      setBtn(btnEnu[1]);
      setButtonValue('1');
    } else if (value.target.value === 'sport') {
      setBtn(btnEnu[2]);
      setButtonValue('3');
    } else if (value.target.value === 'showbiz') {
      setBtn(btnEnu[3]);
      setButtonValue('4');
    } else if (value.target.value === 'lifestyle') {
      setBtn(btnEnu[4]);
      setButtonValue('5');
    } else if (value.target.value === 'sci-tech') {
      setBtn(btnEnu[5]);
      setButtonValue('184');
    }
  }

  return (
    <>
      <div className="homePage" style={{ backgroundColor: '#f0f2f5' }}>
        <div className="leftSide">
          <div className="firstRow">
            <div className="visitCount">
              <div className="circleGrey">
                <img id="histogram" src="./histogram.svg" alt="histogram" />
              </div>
              <div className="spans">
                <span
                  style={{
                    fontWeight: '400',
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    lineHeight: '28px',
                    letterSpacing: '-0.02em',
                    paddingTop: '16px',
                  }}
                >
                  Posjeta
                </span>
                <div
                  style={{
                    paddingBottom: '15px',
                  }}
                >
                  <span
                    style={{
                      fontWeight: '700',
                      fontFamily: 'Roboto',
                      fontSize: '24px',
                      lineHeight: '30px',
                      letterSpacing: '-0.02em',
                    }}
                  >
                    {countVisits}
                  </span>
                  {visitsDiff !== undefined && visitsDiff > 0 && (
                    <img id="positiveArrow" src="./positiveArrow.svg" alt="positiveArrow" />
                  )}
                  {visitsDiff !== undefined && visitsDiff < 0 && (
                    <img id="negativeArrow" src="./negativeArrow.svg" alt="negativeArrow" />
                  )}
                  {visitsDiff !== undefined && visitsDiff === 0 && (
                    <span
                      style={{
                        width: '8px',
                        height: '5px',
                        marginLeft: '10px',
                        marginBottom: '10px',
                        marginRight: '5.87px',
                      }}
                    ></span>
                  )}
                  <span
                    style={{
                      fontWeight: '700',
                      fontFamily: 'Roboto',
                      fontSize: '12px',
                      lineHeight: '30px',
                      letterSpacing: '-0.02em',
                      position: 'relative',
                      top: '-4px',
                      color:
                        visitsDiff !== undefined && visitsDiff > 0
                          ? '#05CD99'
                          : visitsDiff !== undefined && visitsDiff < 0
                          ? 'red'
                          : '#b8b8b8',
                    }}
                  >
                    {visitsDiff !== undefined && Math.abs(visitsDiff).toFixed(1) + '%'}
                  </span>
                  <span
                    style={{
                      fontWeight: '400',
                      fontFamily: 'Roboto',
                      fontSize: '14px',
                      color: '#333333',
                      lineHeight: '28px',
                      letterSpacing: '-0.02em',
                      position: 'relative',
                      top: '-4px',
                      paddingLeft: 3,
                    }}
                  >
                    u odnosu na jučer
                  </span>
                </div>
              </div>
            </div>
            <div className="hSeparator"></div>
            <div className="pendingArticles">
              <div className="circle">
                <a id="checkMark" href={'/articles?status=pending'}>
                  <img id="checkMark" src="./checkMark.svg" alt="checkMark" />
                </a>
              </div>
              <div className="spans">
                <a className="spans" style={{ color: 'rgba(0, 0, 0, 0.85)' }} href={'/articles?status=pending'}>
                  <span
                    style={{
                      fontWeight: '400',
                      fontFamily: 'Roboto',
                      fontSize: '14px',
                      lineHeight: '28px',
                      letterSpacing: '-0.02em',
                      paddingTop: '16px',
                    }}
                  >
                    Članci na čekanju
                  </span>
                  <span
                    style={{
                      fontWeight: '700',
                      fontFamily: 'Roboto',
                      fontSize: '24px',
                      lineHeight: '30px',
                      letterSpacing: '-0.02em',
                      paddingBottom: '15px',
                    }}
                  >
                    {countPendingArticles}
                  </span>
                </a>
              </div>
            </div>
            <div className="hSeparator"></div>
            <div className="todayArticles">
              <div className="circleGrey">
                <img id="file" src="./file.svg" alt="file" />
              </div>
              <div className="spans">
                <span
                  style={{
                    fontWeight: '400',
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    lineHeight: '28px',
                    letterSpacing: '-0.02em',
                    paddingTop: '16px',
                  }}
                >
                  Današnji članci
                </span>
                <div
                  style={{
                    paddingBottom: '15px',
                  }}
                >
                  <span
                    style={{
                      fontWeight: '700',
                      fontFamily: 'Roboto',
                      fontSize: '24px',
                      lineHeight: '30px',
                      letterSpacing: '-0.02em',
                    }}
                  >
                    {countToday}
                  </span>
                  {articleDiff !== undefined && articleDiff > 0 && (
                    <img id="positiveArrow" src="./positiveArrow.svg" alt="positiveArrow" />
                  )}

                  {articleDiff !== undefined && articleDiff < 0 && (
                    <img id="negativeArrow" src="./negativeArrow.svg" alt="negativeArrow" />
                  )}

                  {articleDiff !== undefined && articleDiff === 0 && (
                    <span
                      style={{
                        width: '8px',
                        height: '5px',
                        marginLeft: '10px',
                        marginBottom: '10px',
                        marginRight: '5.87px',
                      }}
                    ></span>
                  )}
                  <span
                    style={{
                      fontWeight: '700',
                      fontFamily: 'Roboto',
                      fontSize: '12px',
                      lineHeight: '30px',
                      letterSpacing: '-0.02em',
                      position: 'relative',
                      top: '-4px',
                      color:
                        articleDiff !== undefined && articleDiff > 0
                          ? '#05CD99'
                          : articleDiff !== undefined && articleDiff < 0
                          ? 'red'
                          : '#b8b8b8',
                    }}
                  >
                    {articleDiff !== undefined && Math.abs(articleDiff)}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="vSeperator"></div>
          <div className="secondRow">
            <div className="topContent">
              <p>Najčitanije</p>

              <div className="btns">
                <button
                  className={btn === btnEnu[0] ? 'btnWhite mbtn active-button' : 'btnWhite mbtn'}
                  onClick={handleButtons}
                  value="sve"
                >
                  sve
                </button>
                <button
                  className={btn === btnEnu[1] ? 'btnWhite mbtn active-button' : 'btnWhite mbtn'}
                  onClick={handleButtons}
                  value="vijesti"
                >
                  vijesti
                </button>
                <button
                  className={btn === btnEnu[2] ? 'btnWhite mbtn active-button' : 'btnWhite mbtn'}
                  onClick={handleButtons}
                  value="sport"
                >
                  sport
                </button>
                <button
                  className={btn === btnEnu[3] ? 'btnWhite mbtn active-button' : 'btnWhite mbtn'}
                  onClick={handleButtons}
                  value="showbiz"
                >
                  showbiz
                </button>
                <button
                  className={btn === btnEnu[4] ? 'btnWhite mbtn active-button' : 'btnWhite mbtn'}
                  onClick={handleButtons}
                  value="lifestyle"
                >
                  lifestyle
                </button>
                <button
                  className={btn === btnEnu[5] ? 'btnWhite mbtn active-button' : 'btnWhite mbtn'}
                  onClick={handleButtons}
                  value="sci-tech"
                >
                  tehnologija
                </button>
              </div>
              <Select
                defaultValue="lastHour"
                style={{
                  width: 150,
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  marginRight: 24,
                }}
                onChange={onReadDateChange}
              >
                <Option value="lastHour">{t('article:lastHour')}</Option>
                <Option value="today">{t('article:today')}</Option>
                <Option value="yesterday">{t('article:yesterday')}</Option>
                <Option value="lastWeek">{t('article:lastWeek')}</Option>
              </Select>
            </div>
            <div className="bottomContent">
              <Row gutter={[8, 16]}>
                <Table
                  bordered
                  sticky
                  size="middle"
                  loading={isLoading || isFetching}
                  dataSource={transform(mostReads)}
                  pagination={false}
                >
                  <Column key="title" dataIndex="title" title={t('article:title')} filterSearch />
                  <Column key="category" width={150} dataIndex="category" title={t('article:category')} filterSearch />

                  <Column key="author" width={150} dataIndex="author" title={t('article:Author')} filterSearch />
                  <Column
                    key="numberOfHits"
                    width={100}
                    dataIndex="numberOfHits"
                    title={t('article:numberOfHits')}
                    filterSearch
                  />
                </Table>
              </Row>
            </div>
          </div>
          <div className="vSeperator"></div>
          <div className="thirdRow" style={{ display: 'flex' }}>
            <div className="groupedColumns" style={{ width: '50%' }}>
              <p
                style={{
                  fontFamily: 'DM Sans',
                  fontSize: 24,
                  fontWeight: 700,
                  letterSpacing: '-0.02em',
                  textAlign: 'left',
                  marginLeft: 25,
                  marginBottom: 0,
                  marginTop: 23,
                }}
              >
                Vrste članaka
              </p>
              <GroupedColumn props={textType} />
            </div>

            <div className="hSeparator"></div>

            <div className="pieChart" style={{ width: '50%' }}>
              <div style={{ display: 'flex' }}>
                <p
                  style={{
                    marginTop: 23,
                    marginBottom: 'auto',
                    marginLeft: 28,
                    fontFamily: 'DM Sans',
                    fontSize: 24,
                    fontWeight: 700,
                    letterSpacing: '-0.02em',
                    textAlign: 'left',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  Članci po kategorijama
                </p>
                <Select
                  defaultValue="today"
                  style={{
                    width: 150,
                    marginTop: 23,
                    marginBottom: 10,
                    right: 0,
                    marginLeft: 'auto',
                    marginRight: 24,
                  }}
                  onChange={onDateChange}
                >
                  <Option value="today">{t('images:Today')}</Option>
                  <Option value="yesterday">{t('images:Yesterday')}</Option>
                  <Option value="lastWeek">{t('images:Last week')}</Option>
                  <Option value="lastMonth">{t('images:Last month')}</Option>
                </Select>
              </div>
              <PieChart props={categories} />
            </div>
          </div>
        </div>
        <div className="hSeparator"></div>
        <div className="rightSide" style={{ background: 'white' }}>
          <h1
            style={{
              paddingTop: '15px',
              marginLeft: '32px',
              fontFamily: 'Roboto',
              fontWeight: '400',
              fontSize: '14px',
              color: '#8B8B8B',
            }}
          >
            Najnovije na portalima
          </h1>
          <div className="flex" style={{ marginTop: '19px' }}>
            <button
              className="bh-button"
              onClick={() => {
                setLocal(true);
                setForeign(false);
                setRegional(false);
              }}
              style={
                local === true
                  ? { background: '#333333', color: 'white' }
                  : { background: 'white', color: '#8B8B8B', fontWeight: 'bold' }
              }
            >
              BH
            </button>
            <button
              className="regional-button"
              onClick={() => {
                setLocal(false);
                setForeign(false);
                setRegional(true);
              }}
              style={
                regional === true
                  ? { background: '#333333', color: 'white', fontWeight: 'bold' }
                  : { background: 'white', color: '#8B8B8B' }
              }
            >
              REGIONALNI
            </button>
            <button
              className="world-button"
              onClick={() => {
                setLocal(false);
                setForeign(true);
                setRegional(false);
              }}
              style={
                foreign === true
                  ? { background: '#333333', color: 'white', fontWeight: 'bold' }
                  : { background: 'white', color: '#8B8B8B' }
              }
            >
              SVJETSKI
            </button>
          </div>
          <div className="rss-feed" style={{ maxHeight: '500px', overflowY: 'auto', padding: '15px' }}>
            <div className={local === false ? 'hidden' : ''}>
              {RSSFeed?.data.local.map((feed, i) => {
                let replacedFeed = feed.link.replace(/.+\/\/|www.|\..+/g, '');
                replacedFeed = replacedFeed.replace(/-/g, ' ');
                let color = '';
                if (replacedFeed === 'klix') {
                  color = 'rss-klix';
                } else if (replacedFeed === 'slobodna bosna') {
                  color = 'rss-slobodna-bosna';
                } else if (replacedFeed === 'depo') {
                  color = 'rss-depo';
                }
                let date = DateTime.fromHTTP(feed.pubDate).setLocale('bs').toRelative();
                return (
                  <div className="flex relative rss-card" key={i}>
                    <div className="rss-card-info">
                      <div className="flex justify-between rss-card-header">
                        <span className={classNames('rss-card-stamp', color)}>{replacedFeed}</span>
                        <span className="rss-card-time-ago">{date}</span>
                      </div>

                      <div className="rss-card-title">
                        <a style={{ color: '#333333' }} href={`${feed.link}`} target="_blank" rel="noreferrer">
                          {feed.title}
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={regional === false ? 'hidden' : ''}>
              {RSSFeed?.data.regional.map((feed, i) => {
                let date = DateTime.fromHTTP(feed.pubDate).setLocale('bs').toRelative();
                let replacedFeed = feed.link.replace(/.+\/\/|www.|\..+/g, '');
                replacedFeed = replacedFeed.replace(/-/g, ' ');
                let color = '';
                if (replacedFeed === 'kurir') {
                  color = 'rss-kurir';
                } else if (replacedFeed === 'blic') {
                  color = 'rss-blic';
                } else if (replacedFeed === 'vijesti') {
                  color = 'rss-vijesti';
                } else if (replacedFeed === '24sata') {
                  color = 'rss-24sata';
                } else if (replacedFeed === 'index') {
                  color = 'rss-index';
                } else if (replacedFeed === 'vecernji') {
                  color = 'rss-vecernji';
                }

                return (
                  <div className="flex relative rss-card" key={i}>
                    <div className="rss-card-info">
                      <div className="flex justify-between article-card-header">
                        <span className={classNames('rss-card-stamp', color)}>{replacedFeed}</span>
                        <span className="rss-card-time-ago">{date}</span>
                      </div>
                      <div className="rss-card-title">
                        <a style={{ color: '#333333' }} href={`${feed.link}`} target="_blank" rel="noreferrer">
                          {feed.title}
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={foreign === false ? 'hidden' : ''}>
              {RSSFeed?.data.foreign.map((feed, i) => {
                let date = DateTime.fromHTTP(feed.pubDate).setLocale('bs').toRelative();
                let replacedFeed = feed.link.replace(/.+\/\/|www.|\..+/g, '');
                replacedFeed = replacedFeed.replace(/-/g, ' ');
                let color = '';
                if (replacedFeed === 'dailymail') {
                  color = 'rss-dailymail';
                } else if (replacedFeed === 'theguardian') {
                  color = 'rss-theguardian';
                }

                return (
                  <div className="flex relative rss-card" key={i}>
                    <div className="rss-card-info">
                      <div className="flex justify-between article-card-header">
                        <span className={classNames('rss-card-stamp', color)}>{replacedFeed}</span>
                        <span className="rss-card-time-ago">{date}</span>
                      </div>
                      <div className="rss-card-title">
                        <a style={{ color: '#333333' }} href={`${feed.link}`} target="_blank" rel="noreferrer">
                          {feed.title}
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="vSeperator"></div>
          <div className="topContent">
            <p>Kalendar</p>

            <Select
              defaultValue="today"
              style={{
                width: 100,
                marginTop: 'auto',
                marginBottom: 'auto',
                marginRight: 10,
              }}
              onChange={onCalendarDateChange}
            >
              <Option value="today">{'Danas'}</Option>
              <Option value="nextWeek">{'7 dana'}</Option>
            </Select>

            <button
              onClick={() => {
                setShowCalendarEntryModal(true);
              }}
              className="create-calendarEntry-button"
              style={{
                marginRight: 20,
                width: 35,
                height: 35,
                marginTop: 'auto',
                marginBottom: 'auto',
                backgroundColor: 'white',
                border: '1px solid #d9d9d9',
                borderRadius: '5px',
              }}
            >
              {' '}
              +{' '}
            </button>
          </div>
          {!entriesLoading && (
            <div className="calendar" style={{ maxHeight: '500px', overflowY: 'auto', padding: '15px' }}>
              {calendarEntries.data.map((feed: any, i: number) => {
                return (
                  <div className="flex relative rss-card" key={i}>
                    <div className="rss-card-info">
                      <div className="flex justify-between rss-card-header">
                        <span style={{ color: '#ed1c24' }} className={classNames('rss-card-stamp')}>
                          {feed.time}
                        </span>
                        {isWeekSelected && (
                          <span style={{ color: '#ed1c24' }} className={classNames('rss-card-stamp')}>
                            {feed.entryDate.slice(8, 10) +
                              '.' +
                              feed.entryDate.slice(5, 7) +
                              '.' +
                              feed.entryDate.slice(0, 4)}
                          </span>
                        )}
                      </div>

                      <div className="rss-card-title">
                        <span style={{ color: '#333333' }} className={classNames('rss-card-stamp')}>
                          {feed.text}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <CalendarAddEntryModal
            visible={showCalendarEntryModal}
            onClose={toggleCalendarAddEntryModal}
            onConfirm={toggleCalendarAddEntryModal}
            entriesRefetch={entriesRefetch}
          />
        </div>
      </div>
    </>
  );
};

import { EyeOutlined } from '@ant-design/icons';
import { Row, Table, Spin, DatePicker, Tooltip, Button, Input } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { blockUpdateLogService, usersService } from 'config/services';

import { HttpError } from 'helpers/http';

import { PaginationParams } from 'types/common';
import { BlockUpdateLog, BlockUpdateLogOptions, PaginatedBlockUpdateLog } from 'types/services/block-update-log';
import { PaginatedUsers } from 'types/services/users';

import { transformBlockUpdateLogs } from './utils';

export const BlockUpdateLogs = () => {
  const { Column } = Table;
  const { t } = useTranslation();
  const [pagination, setPagination] = useState<PaginationParams>({ page: 1, perPage: 10 });
  const [filters, setFilters] = useState<BlockUpdateLogOptions>({});

  const newDateFrom = new Date();
  newDateFrom.setDate(newDateFrom.getDate() - 1);

  const newDateTo = new Date();
  newDateTo.setDate(newDateTo.getDate() + 1);

  const [date1, setDate1] = useState(newDateFrom.toISOString().replace('T', ' ').substring(0, 10));
  const [date2, setDate2] = useState(newDateTo.toISOString().replace('T', ' ').substring(0, 10));

  const {
    isLoading,
    isFetching,
    isError,
    data: response,
    error,
    refetch,
  } = useQuery<PaginatedBlockUpdateLog, HttpError>(['list'], () => blockUpdateLogService.list(pagination, filters));

  const {
    isError: isErrorUsers,
    data: paginatedUsers,
    error: usersError,
  } = useQuery<PaginatedUsers, HttpError>('listUsers', () =>
    usersService.list({ page: 1, perPage: 1000, filters: {} }),
  );

  useEffect(() => {
    refetch();
  }, [pagination, filters, refetch]);

  useEffect(() => {
    setFilters({ dateOne: date1, dateTwo: date2, user: filters.user });
  }, [date1, date2, filters.user, refetch]);

  function onPaginationChange(page: number) {
    setPagination((previousPagination) => ({ ...previousPagination, page }));
  }

  function onShowSizeChange(current: number, pageSize: number) {
    setPagination({ ...pagination, page: current, perPage: pageSize });
  }

  let userArr: Array<number> = [];

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setFilters({ user: filters.user });
    // setPagination((p) => ({ ...p, page: 1 }));
  };

  const handleFromDate = (values: any) => {
    setDate1(values);
  };
  const handleToDate = (values: any) => {
    setDate2(values);
  };

  if (isError || isErrorUsers) {
    return (
      <div>
        <pre>{JSON.stringify(error || usersError, undefined, 2)}</pre>
      </div>
    );
  }

  if (!response || !paginatedUsers) {
    return (
      <div style={{ padding: '10px', textAlign: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }

  const users = paginatedUsers.data;
  const userFilterOptions = users.map((user) => ({ text: `${user.firstName} ${user.lastName}`, value: user.id }));

  userFilterOptions.map((u) => userArr.push(u.value));

  function transform(logs: BlockUpdateLog[]) {
    return logs.map((log) => transformBlockUpdateLogs(log));
  }

  const { data: logs } = response;
  const { total } = response.pagination;
  const { RangePicker } = DatePicker;
  const dateFormat = 'YYYY/MM/DD';

  return (
    <>
      <div style={{ padding: '10px', textAlign: 'center' }}>
        <RangePicker
          defaultValue={[moment(date1, dateFormat), moment(date2, dateFormat)]}
          format={dateFormat}
          onChange={(values, e) => {
            handleFromDate(values?.[0]?.format('YYYY-MM-DD'));
            handleToDate(values?.[1]?.format('YYYY-MM-DD'));
          }}
          style={{ marginBottom: 20 }}
        />
        <Row gutter={[8, 16]}>
          <Table
            bordered
            sticky
            size="middle"
            loading={isLoading || isFetching}
            dataSource={transform(logs)}
            pagination={{
              onChange: onPaginationChange,
              onShowSizeChange,
              size: 'default',
              position: ['bottomCenter'],
              showSizeChanger: true,
              showLessItems: true,
              current: pagination.page,
              pageSize: pagination.perPage,
              total,
              defaultPageSize: 10,
            }}
            onChange={handleTableChange}
          >
            <Column
              key="user"
              dataIndex="user"
              title={t('common:User')}
              filters={userFilterOptions}
              filterSearch
              filteredValue={filters.user}
            />
            {/* <Column
              key="body"
              dataIndex="body"
              title={t('common:Changes')}
              filters={userFilterOptions}
              filterSearch
            /> */}
            <Column
              key="createdAt"
              dataIndex="createdAt"
              title={t('common:Created At')}
              //filters={userFilterOptions}
              filterSearch
            />
            <Column
              key="createdAt"
              dataIndex="createdAt"
              title={t('common:Actions')}
              //filters={userFilterOptions}
              filterSearch
              align={'center'}
              render={(text, record: any) => (
                <a style={{ color: '#598ad9' }} href={'/block-update-log/' + record.id}>
                  <Tooltip title={t('common:Open')}>
                    <Button shape="round" icon={<EyeOutlined />} />
                  </Tooltip>
                </a>
              )}
            />
          </Table>
        </Row>
      </div>
    </>
  );
};

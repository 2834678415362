import { FormOutlined } from '@ant-design/icons';
import { Button, Modal, Select, Input } from 'antd';
import { TimePicker, DatePicker } from 'antd';
import type { TimePickerProps, DatePickerProps } from 'antd';
import dayjs from 'dayjs';
import { useState, useCallback, useEffect, useRef } from 'react';
// eslint-disable-next-line import/order
import { useTranslation } from 'react-i18next';

import 'react-easy-crop/react-easy-crop.css';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { usersService } from 'config/services';
import { calendarEntriesService } from 'config/services';

import { HttpError } from 'helpers/http';

import { PaginatedUsers } from 'types/services/users';
import { SessionState, StoreState } from 'types/store';

export interface CalendarAddEntryModalProps {
  visible: boolean;
  onClose: () => void;
  onConfirm: () => void;
  entriesRefetch: () => void;
}

export const CalendarAddEntryModal = ({ visible, onClose, onConfirm, entriesRefetch }: CalendarAddEntryModalProps) => {
  const { t } = useTranslation();
  const [entryText, setEntryText] = useState('');
  const [entryTime, setEntryTime] = useState('');
  const [entryDate, setEntryDate] = useState('');
  const { user } = useSelector<StoreState, SessionState>(({ session }) => session);
  const [entryUser, setEntryUser] = useState(user?.email || '');
  const [modalWarning, setModalWarning] = useState(false);

  const resetState = () => {
    setEntryText('');
    // setEntryTime('');
    // setEntryDate('');
    setEntryUser(user?.email || '');
    setModalWarning(false);
  };

  const { TextArea } = Input;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isLoading: usersLoading, data: usersData } = useQuery<PaginatedUsers, HttpError>('listUsers', () =>
    usersService.list({ page: 1, perPage: 1000, filters: {} }),
  );

  const { mutateAsync: createCalendarEntry } = useMutation(
    'createCrossword',
    ({ text, time, date, user }: { text: string; time: string; date: string; user: string }) =>
      calendarEntriesService.create({ text, time, date, user }),
  );

  const submitCalendarEntry = useCallback(async () => {
    try {
      //console.log('prije poziva createa vrijednosti', entryTime, entryDate, entryUser);

      if (entryText !== '' && entryTime !== '' && entryDate !== '') {
        createCalendarEntry({ text: entryText, time: entryTime, date: entryDate, user: entryUser }).finally(() => {
          entriesRefetch();
          resetState();
          onConfirm();
        });
      } else {
        setModalWarning(true);
      }
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entryText, entryTime, entryDate, entryUser]);

  const onChangeText = (text: any) => {
    //console.log(text.target.value, 'uneseni tekst');
    setEntryText(text.target.value);
  };

  const onChangeTime: TimePickerProps['onChange'] = (time, timeString) => {
    // console.log(time, timeString);
    setEntryTime(timeString);
  };

  const onChangeDate: DatePickerProps['onChange'] = (date, dateString) => {
    // console.log(date, dateString);
    setEntryDate(dateString);
  };

  const onChangeUser = (selectedUserEmail: any) => {
    // console.log(selectedUserEmail, 'USER', ' loggedUser ', entryUser);
    setEntryUser(selectedUserEmail);
  };

  const format = 'HH:mm';

  return (
    <Modal
      width={500}
      centered
      mask={true}
      maskClosable={false}
      visible={visible}
      onCancel={() => {
        resetState();
        onClose();
      }}
      title={
        <div>
          <FormOutlined style={{ marginRight: '5px' }} />
          <span>Dodaj novi podsjetnik u kalendar</span>
        </div>
      }
      footer={[
        <Button key="submit" type="primary" onClick={submitCalendarEntry}>
          {t('common:Confirm')}
        </Button>,
      ]}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          minHeight: '250px',
          alignItems: 'center',
        }}
      >
        <TextArea
          onChange={onChangeText}
          value={entryText}
          placeholder="Tekst podsjetnika"
          autoSize
          style={{ width: 250 }}
          maxLength={150}
        />
        <TimePicker onChange={onChangeTime} format={format} style={{ width: 250, marginTop: '10px' }} />
        <DatePicker
          onChange={onChangeDate}
          style={{ width: 250, marginTop: '10px' }}
          disabledDate={(current) => {
            return current < dayjs().startOf('day');
          }}
        />
        {(user?.role === 'EDITOR' || user?.role === 'ADMINISTRATOR') && (
          <Select
            showSearch
            style={{ width: 250, marginTop: '10px' }}
            placeholder={'Odaberite korisnika'}
            onChange={onChangeUser}
            options={usersData?.data.map((user) => ({
              label: `${user.firstName} ${user.lastName}`,
              value: user.email,
            }))}
            filterOption={(input, option) =>
              option?.label ? (option?.label as string)?.toLowerCase().includes(input.toLowerCase()) : false
            }
          />
        )}
        {modalWarning && <p style={{ color: 'red' }}>{'Morate unijeti sva polja prije kreiranja!'}</p>}
      </div>
    </Modal>
  );
};

import { EditTwoTone, EyeOutlined } from '@ant-design/icons';
import { Row, Table, Spin, DatePicker, Tag, Tooltip, Button, Input, InputNumber } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { activityLogService, usersService } from 'config/services';

import { HttpError } from 'helpers/http';

import { Articles } from 'routes/articles/Articles';
import { routes } from 'routes/routes';

import { PaginationParams } from 'types/common';
import { ActivityLog, ActivityLogOptions, PaginatedActivityLog } from 'types/services/activity-log';
import { PaginatedUsers } from 'types/services/users';

import { transformLogs } from './utils';

export const ActivityLogs = () => {
  const { Column } = Table;
  const { t } = useTranslation();
  const history = useHistory();
  const [pagination, setPagination] = useState<PaginationParams>({ page: 1, perPage: 10 });
  const [filters, setFilters] = useState<ActivityLogOptions>({});

  const newDateFrom = new Date();
  newDateFrom.setDate(newDateFrom.getDate() - 1);

  const newDateTo = new Date();
  newDateTo.setDate(newDateTo.getDate() + 1);

  const [date1, setDate1] = useState(newDateFrom.toISOString().replace('T', ' ').substring(0, 10));
  const [date2, setDate2] = useState(newDateTo.toISOString().replace('T', ' ').substring(0, 10));
  const [authorId, setAuthorId] = useState<Number>(-1);
  const [isBottomVisible, setIsBottomVisible] = useState(false);
  const filterInputNode = useRef<typeof Input>(null);

  const {
    isLoading,
    isFetching,
    isError,
    data: response,
    error,
    refetch,
  } = useQuery<PaginatedActivityLog, HttpError>(['list'], () => activityLogService.list(pagination, filters));

  const {
    isError: isErrorUsers,
    data: paginatedUsers,
    error: usersError,
  } = useQuery<PaginatedUsers, HttpError>('listUsers', () =>
    usersService.list({ page: 1, perPage: 1000, filters: {} }),
  );

  useEffect(() => {
    refetch();
  }, [pagination, filters, refetch]);

  useEffect(() => {
    setFilters({ dateOne: date1, dateTwo: date2, user: filters.user });
  }, [date1, date2, filters.user, refetch]);

  function onPaginationChange(page: number) {
    setPagination((previousPagination) => ({ ...previousPagination, page }));
  }

  function onShowSizeChange(current: number, pageSize: number) {
    setPagination({ ...pagination, page: current, perPage: pageSize });
  }

  let userArr: Array<number> = [];

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setFilters({ user: filters.user });
    setPagination((p) => ({ ...p, page: 1 }));
  };

  const handleFromDate = (values: any) => {
    setDate1(values);
  };
  const handleToDate = (values: any) => {
    setDate2(values);
  };

  const handleUserClick = (event: any) => {
    const currUser = event.target.innerText;
    const firstName = currUser.split(' ')[0];
    const lastName = currUser.slice(currUser.indexOf(' ') + 1);

    users?.forEach((m) => {
      if (m.firstName === firstName && m.lastName === lastName) {
        setFilters({ user: [m.id] });
      }
    });
  };

  if (isError || isErrorUsers) {
    return (
      <div>
        <pre>{JSON.stringify(error || usersError, undefined, 2)}</pre>
      </div>
    );
  }

  if (!response || !paginatedUsers) {
    return (
      <div style={{ padding: '10px', textAlign: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }

  const users = paginatedUsers.data;
  const userFilterOptions = users.map((user) => ({ text: `${user.firstName} ${user.lastName}`, value: user.id }));

  userFilterOptions.map((u) => userArr.push(u.value));

  function transform(logs: ActivityLog[]) {
    return logs.map((log) => transformLogs(log));
  }

  const titleFilterDropdown = () => (
    <div style={{ padding: 8 }}>
      <Input.Search
        value={filters.title}
        placeholder={t('common:Search term')}
        onChange={({ target }) => setFilters((f) => ({ ...f, title: [target.value] }))}
        onSearch={() => setPagination({ ...pagination, filters, page: 1 })}
        ref={(node) => {
          (filterInputNode as any).current = node;
        }}
      />
    </div>
  );

  const translateEvent = (event: string) => {
    let color = 'green';
    if (event === 'delete') {
      color = 'red';
    } else if (event === 'update') {
      color = 'orange';
    }
    return <Tag color={color}>{t(`article:${event}`)}</Tag>;
  };

  function navigateToArticle(id: number) {
    console.log(routes);

    const activityLogRoute = (routes.find((r) => r.key === 'activity-log')?.path as string | undefined)?.replace(
      ':id',
      id.toString(),
    );

    activityLogRoute && history.push(activityLogRoute);
  }

  const idFilterDropdown = () => (
    <div style={{ padding: 8 }}>
      <InputNumber
        value={filters.articleId}
        placeholder={t('common:Search term')}
        onChange={(target) => {
          if (target) {
            setFilters((f) => ({ ...f, articleId: target }));
          } else {
            setFilters(() => ({}));
          }
        }}
        ref={(node) => {
          (filterInputNode as any).current = node;
        }}
      />
    </div>
  );

  const { data: logs } = response;
  // const { total } = response.pagination;
  // add following lines to table pagination property to enable pagination
  // current: pagination.page,
  // pageSize: pagination.perPage,
  // total,
  const { RangePicker } = DatePicker;
  const dateFormat = 'YYYY/MM/DD';

  return (
    <>
      <div style={{ padding: '10px', textAlign: 'center' }}>
        <RangePicker
          defaultValue={[moment(date1, dateFormat), moment(date2, dateFormat)]}
          format={dateFormat}
          onChange={(values, e) => {
            handleFromDate(values?.[0]?.format('YYYY-MM-DD'));
            handleToDate(values?.[1]?.format('YYYY-MM-DD'));
          }}
          style={{ marginBottom: 20 }}
        />
        <Row gutter={[8, 16]}>
          <Table
            bordered
            sticky
            size="middle"
            loading={isLoading || isFetching}
            dataSource={transform(logs)}
            pagination={{
              onChange: onPaginationChange,
              onShowSizeChange,
              size: 'default',
              position: ['bottomCenter'],
              showSizeChanger: true,
              showLessItems: true,
              defaultPageSize: 10,
            }}
            onChange={handleTableChange}
          >
            <Column
              key="article.id"
              dataIndex="article.id"
              title={t('article:Article Id')}
              filterDropdown={idFilterDropdown}
              width={95}
              render={(text, art: any) => {
                console.log(art.article.id);
                return (
                  <a style={{ color: '#598ad9' }} href={'/article/' + art.article.id}>
                    {art.article.id}
                  </a>
                );
              }}
            />
            <Column
              key="title"
              dataIndex="title"
              title={t('common:Article')}
              //filters={userFilterOptions}
              filterSearch
              filterDropdown={titleFilterDropdown}
              //#0000EE}
              render={(text, art: any) => (
                <a style={{ color: '#598ad9' }} href={'/article/' + art.id}>
                  {art.title}
                </a>
              )}
              filteredValue={filters?.title}
            />
            <Column
              key="event"
              dataIndex="event"
              title={t('common:Action')}
              //filters={userFilterOptions}
              filterSearch
              render={translateEvent}
              align={'center'}
            />

            <Column
              key="user"
              dataIndex="user"
              title={t('common:User')}
              filters={userFilterOptions}
              filterSearch
              filteredValue={filters.user}
            />
            {/* <Column
              key="body"
              dataIndex="body"
              title={t('common:Changes')}
              filters={userFilterOptions}
              filterSearch
            /> */}
            <Column
              key="createdAt"
              dataIndex="createdAt"
              title={t('common:Created At')}
              //filters={userFilterOptions}
              filterSearch
            />
            <Column
              key="createdAt"
              dataIndex="createdAt"
              title={t('common:Actions')}
              //filters={userFilterOptions}
              filterSearch
              align={'center'}
              render={(text, record: any) => (
                <a style={{ color: '#598ad9' }} href={'/activity-log/' + record.id}>
                  <Tooltip title={t('common:Open')}>
                    <Button shape="round" icon={<EyeOutlined />} />
                  </Tooltip>
                </a>
              )}
            />
          </Table>
        </Row>
      </div>

      {authorId && (
        <Articles
          authorId={authorId}
          dateFrom={date1}
          dateTo={date2}
          status={['approved']}
          visible={isBottomVisible}
        ></Articles>
      )}
    </>
  );
};

import { HttpClient } from 'helpers/http';

import { CalendarEntriesOptions } from 'types/services/homeStatistics';

export interface CalendarEntry {
  entryText: string;
  entryTime: string;
  entryDate: string;
  entryUser: string;
}

export class CalendarEntriesService {
  constructor(private client: HttpClient) {}

  API = {
    root: '/api/calendarEntry',
  };

  create = async (parameters: any) => {
    const { text, time, date, user } = parameters;
    //console.log(text, time, date, user, 'sve sto je dobio prije poziva');
    const url = this.API.root;

    return this.client.post<CalendarEntry>(url, { entryText: text, entryTime: time, entryDate: date, entryUser: user });
  };

  list = (options: CalendarEntriesOptions = {}) => {
    let url = `${this.API.root}?`;
    const params: Record<string, any> = { ...options };
    //console.log('poziv lista datumi od i do', params);

    url =
      url +
      Object.keys(params)
        .filter((key) => !!params[key])
        .map((key) => {
          const value = params[key];
          return Array.isArray(value)
            ? value.map((v) => `${key}=${encodeURIComponent(v)}`).join('&')
            : `${key}=${encodeURIComponent(value)}`;
        })
        .join('&');
    return this.client.get<any>(url);
  };
}
